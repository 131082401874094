.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
  display: inline-block;
  margin-right: 4px;
  color: #a61d24;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*' !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  content: '' !important;
  display: none !important;
}
/* TEST COMMIT */
